import React, { useEffect } from "react";
import styled from "styled-components";
import {applyButton, flexColStSt, palette} from "../../styles/styles";
import { WatchLater } from "@styled-icons/material-outlined";
import { People } from "@styled-icons/ionicons-outline";
import { graphql, useStaticQuery } from "gatsby";

const ContentInfo = (props) => {
  const { className, location } = props;
  const module = location.state?.index || 0;
  const modules = [
    {
      title: "Capacitaciones grupales con expertos (en vivo)",
      desc: "Nuestro expertos han diseñado un programa que te ayudará a encontrar el empleo que tanto estás buscando. ",
      content: [
        {
          name: "Módulo 1 - Prepárate",
          desc: "Para comenzar, entenderás cuáles son las bases para conseguir empleo en la realidad actual y cómo ser un candidat@ atractiv@ para las empresas.",
        },
        {
          name: "Módulo 2 - Busca empleo",
          desc: "Desarrollarás las habilidades necesarias para diseñar una estrategia de búsqueda de empleo exitosa y aprenderás a utilizar las mejores herramientas para potencializar tus resultados.",
        },
        {
          name: "Módulo 3 - Haz que te contraten",
          desc: "Finalmente, aprenderás qué hacer antes, durante y después de las entrevistas laborales. Adicionalmente, te enseñaremos cómo negociar una oferta laboral.",
        },
      ],
      punch: "¿Quieres conocer el contenido de estos módulos? Aplica ahora.",
      details: {
        lecturers: [
          {
            name: "Azucena Moranchel",
            photo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FAzucena%20.jpeg?alt=media&token=740d2153-f413-492b-8a9a-9914595af694",
          },
          {
            name: "Carolina López",
            photo:
              "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FCarolina%20Lo%CC%81pez%20Solis%20.jpeg?alt=media&token=e2c613b5-c817-4904-86aa-cf53771762de",
          },
          {
            name: "Cynthia Balderas",
            photo:
              "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FCynthia%20Patricia%20Balderas%20Sa%CC%81nchez.jpeg?alt=media&token=e3a6ebfe-16c0-42dd-a3d9-b4d36c78532b",
          },
          {
            name: "Erick Ramírez",
            photo:
              "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FErick%20Rami%CC%81rez.jpeg?alt=media&token=77f2c632-e786-4ba3-a7f9-76e3d0977001",
          },
          {
            name: "Erika Campos",
            photo:
              "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FErika%20Campos.jpeg?alt=media&token=ba4e666c-0be0-4dc6-9fc6-aa776f910571",
          },
          {
            name: "Mario Romellón",
            photo:
              "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FMario%20Romello%CC%81n.jpeg?alt=media&token=69a62c66-f8a1-425a-be09-4802dc3b3c9e",
          },
          {
            name: "Orlando Perales",
            photo:
              "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FOrlando%20Perales.jpeg?alt=media&token=67599c07-174d-4573-9bcd-28142c7222c8",
          },
          { name: "Pol Morral", photo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FPol.jpg?alt=media&token=3821be56-8866-4040-bd80-2d974f05d270" },
        ],
        duration: 10,
        groupSize: 30,
      },
    },
    {
      title: "Sesiones individuales 1 a 1",
      desc: "Queremos acompañarte de manera más personalizada e individual. Por esta razón, contarás con un career coach y con sesiones on demand. A continuación, te explicamos cada uno:",
      content: [
        {
          name: "Career coach",
          desc: "Desde el inicio del programa te asignaremos un expert@ en reclutamiento que te dará seguimiento personalizado e individual por una hora cada 15 días hasta que consigas el empleo de tus sueños. ",
        },
        {
          name: "Sesiones on demand",
          desc: "Adicionalmente, a lo largo del Bootcamp contarás con 5 horas de sesión 1 a 1 personalizada, que podrás repartir con nuestro expertos para realizar alguna de las siguientes actividades: \n",
          someList: [
            "Revisión de CV / Hoja de vida",
            "Revisión de LinkedIn",
            "Asesoría en búsqueda de empleo",
            "Preparación para entrevista",
            "Simulación de entrevista (español o inglés)",
            "Cualquier otro tema en específico",
          ],
        },
      ],
      punch: "¿Quieres conocer a tu career coach? Aplica ahora",
      details: {
        lecturers: [
          {
            name: "Alexa Villegas",
            photo:
              "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FAlexa%20Villegas.jpeg?alt=media&token=cf9bd8c3-bee1-47fa-a171-e94036eb100b",
          },
          { name: "Ana Rodríguez", photo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FAna%20Rodriguez.jpeg?alt=media&token=192d9fcc-c2fb-4ea8-84dc-2fdd405ce9da" },
          {
            name: "Andrea Carmona",
            photo:
              "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FAndrea%20Carmona%20Ramos.jpeg?alt=media&token=63342ad8-c8d0-40b9-a6a8-a1c6d2a337f1",
          },
          {
            name: "Brisa Chumba",
            photo:
              "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FBrisa%20Chumba.jpeg?alt=media&token=63133c0c-88eb-4f57-bd2c-01037438b1b8",
          },
          {
            name: "Cynthia Balderas",
            photo:
              "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FCynthia%20Patricia%20Balderas%20Sa%CC%81nchez.jpeg?alt=media&token=e3a6ebfe-16c0-42dd-a3d9-b4d36c78532b",
          },
          {
            name: "Dania Bórquez",
            photo:
              "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FDania%20Bo%CC%81rquez%20Herna%CC%81ndez.jpeg?alt=media&token=6e1f0cf3-7964-461d-af99-7cc64ec6b01e",
          },
          { name: "Dinora Gómez", photo: "" },
          {
            name: "Erick Ramírez",
            photo:
              "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FErick%20Rami%CC%81rez.jpeg?alt=media&token=77f2c632-e786-4ba3-a7f9-76e3d0977001",
          },
          {
            name: "Erika Campos",
            photo:
              "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FErika%20Campos.jpeg?alt=media&token=ba4e666c-0be0-4dc6-9fc6-aa776f910571",
          },
          {
            name: "Geraldina Untermann",
            photo:
              "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FGeraldina%20Untermann.jpeg?alt=media&token=7cdc8864-c998-473a-b8c7-e17b1a0a4bf6",
          },
          {
            name: "Karina Villamar",
            photo:
              "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FKarina%20Villamar%20Lau.jpeg?alt=media&token=ef071a75-68e6-4cce-a54e-2aee2124cbc1",
          },
          {
            name: "Daniela Bongianino",
            photo:
              "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FMaria%20Daniela%20Bongianino.jpeg?alt=media&token=d50845a1-d22e-4e4b-aa47-147466276f24",
          },
          {
            name: "Mario Romellón",
            photo:
              "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FMaria%20Daniela%20Bongianino.jpeg?alt=media&token=d50845a1-d22e-4e4b-aa47-147466276f24",
          },
          {
            name: "Melanie Jascovich",
            photo:
              "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FMelanie%20Jascovich.jpeg?alt=media&token=7afc529e-e465-4112-8446-c83b22a634c4",
          },
        ],
        duration: 0,
        groupSize: 0,
      },
    },
    {
      title: "Paquete de herramientas digitales incluído",
      desc: "Todo experto necesita las herramientas adecuadas y hemos incluído para ti las mejores herramientas. ;)",
      content: [
        {
          name: "Seguimiento de postulaciones",
          desc: "Te brindaremos sin ningún costo una plataforma en donde podrás dar seguimiento a todas las vacantes en las que apliques. ¡Así nunca olvidarás nada!",
        },
        {
          name: "Growth hacking",
          desc: "Contarás con acceso por un mes a una herramienta que te permitirá contactar hasta 150 reclutadores por día. ¡Buscar empleo nunca había sido tan fácil!",
        },
        {
          name: "Desarrolla tu habilidades ",
          desc: "Tendrás descuentos especiales con las empresas más importantes de e-learning. Así podrás seguir preparándote para ese trabajo que te está esperando.",
        },
      ],
      punch:
        "¿Qué esperas para comenzar a utilizar estas herramientas? Aplica ahora",
      details: { lecturers: [], duration: 0, groupSize: 0 },
    },
  ];
  const info = modules[module];

  const lecturersList = () => {
    const fourToShow = info.details.lecturers
      .sort(() => Math.random() - Math.random())
      .slice(0, 4);
    return fourToShow.map((l) => (
      <div className="miniCard">
        <img alt="Ponente" src={l.photo} />
        <p>{l.name}</p>
      </div>
    ));
  };
  return (
    <section className={className}>
      <div className="mainContent">
        <div>
          <h1>{info.title}</h1>
          <p className="desc">{info.desc}</p>
        </div>
        {info.content.map((part) => (
          <div className="partModule">
            <h3>{part.name}</h3>
            <p>{part.desc}</p>
            {part.someList && part.someList.length ? (
              <ul>
                {part.someList.map((i) => (
                  <li>{i}</li>
                ))}
              </ul>
            ) : null}
          </div>
        ))}
        <p className="punch">{info.punch}</p>
      </div>
      <div className="sideCard">
        {info.details.lecturers.length ? (
          <>
            <h4>Ponentes</h4>
            <div className="miniCards">
            {lecturersList()}
            </div>
          </>
        ) : null}
        {info.details.lecturers.length > 4 ? (
          <p className="andMore">Y más...</p>
        ) : null}
        {info.details.duration ? (
          <div className="miniCards">
            <h4>Duración</h4>
            <div className="miniCard">
              <WatchLater className="icon" />
              <p>{`${info.details.duration} `}horas</p>
            </div>
            <h4>Tamaño de grupos</h4>
            <div className="miniCard">
              <People className="icon" />
              <p>{`${info.details.groupSize} `}lugares</p>
            </div>
          </div>
        ) : null}
        <a
          href="https://form.typeform.com/to/QhcRLoIg"
          target="_blank"
          rel="noopener no referrer"
        >
          Postúlate ahora
        </a>
      </div>
    </section>
  );
};

export default styled(ContentInfo)`
  padding: 0 8%;
  display: grid;
  margin-top: -8rem;
  grid-template-columns: 70% 30%;
  grid-template-rows: repeat(3, auto);
  column-gap: 4rem;
  @media (max-width: 1024px) {
  }
  @media (max-width: 768px) {
    margin-top: -2rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-items: center;
  }
  .mainContent {
    grid-row: 1 / -1;
    margin-top: 4rem;
    padding: 0 8rem 4rem 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 2rem;
    @media (max-width: 768px) {
      padding: 0 4rem 4rem 0;
    }
    h1 {
      font-size: 3rem;
      color: ${palette.fontBlue};
      margin-bottom: 1rem;
    }
    .desc {
      font-size: 2rem;
      color: ${palette.fontModDesc};
      font-family: "SofiaProMediumItalicAz", sans-serif;
      margin-bottom: 2rem;
    }
    .partModule {
      font-size: 2.2rem;
      margin-bottom: 2rem;
      h3 {
        font-family: SofiaProBoldAz, sans-serif;
        color: ${palette.fontBlue};
      }
      p,
      ul > li {
        font-family: SofiaProRegularAz, sans-serif;
        line-height: 4rem;
      }
    }
    .punch {
      font-family: SofiaProBoldAz, sans-serif;
      color: ${palette.blackBlack};
      font-size: 2.2rem;
      margin-top: 2rem;
    }
  }
  .sideCard {
    grid-row: 1 / 2;
   ${flexColStSt};
    row-gap: 1rem;
    @media (max-width: 768px) {
      grid-row: 2 / 3;
      align-items: center;
    }
    h4 {
      font-size: 2.2rem;
      color: ${palette.fontBlue};
    }
    .miniCards {
      ${flexColStSt};
      grid-gap: 2rem;
      @media (max-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 2rem;
      }
      .miniCard {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        column-gap: 1.5rem;
        @media (max-width: 768px) {
          justify-content: center;
        }
        img {
          border-radius: 50%;
          width: 6rem;
          max-height: 6rem;
        }
      }
    }
    p {
      font-size: 2rem;
      line-height: 3rem;
    }
    
    .andMore {
      margin-top: 1rem;
      color: ${palette.fontBlue};
    }
    .icon {
      width: 4rem;
      color: ${palette.fontRed};
    }
    a {
      ${applyButton};
      font-size: 2.5rem;
      margin-top: 2rem;
    }
  }
`;
