import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Done } from "@styled-icons/material";
import { palette } from "../styles/styles";
import Link from "gatsby-link";

const ShortDetailsCard = (props) => {
  const { className, infoCard } = props;
  return (
    <div className={className}>
      <Link className="link" to="/info" state={{ index: infoCard.index }}>
        <StaticImage className="img" src="../images/sections/cardImg.png" alt="card" />
            <h3>{infoCard.name}</h3>
          <div className="content">
            <ul>
              {infoCard.bullets.map((b) => (
                <li>
                  <Done className="icon" />
                  {b}
                </li>
              ))}
            </ul>
          </div>
      </Link>
    </div>
  );
};

export default styled(ShortDetailsCard)`
  cursor: pointer;
  background-color: ${palette.whiteWhite};
  box-shadow: ${palette.regBS};
  border-radius: 10px;
  .link {
    display: grid;
    grid-template-rows: 45% 4rem max-content;
    grid-row-gap: 1rem;
    text-decoration: none;
    height: 100%;
    padding-bottom: 2rem;
    .img{
      border-radius: 10px 10px 0 0;
    }
    h3 {
      font-size: 2rem;
      line-height: 25px;
      font-family: "SofiaProSemiBoldAz", sans-serif;
      color: ${palette.fontBlue};
      padding: 1rem 2rem;
    }
    .content {
      padding: 1rem 2rem;
      line-height: 2.5rem;
      display: grid;
      grid-template-rows: auto;
      ul {
        padding: 0;
        li {
          display: flex;
          flex-direction: row;
          font-size: 1.6rem;
          align-items: center;
          color: #43474c;
          .icon {
            min-width: 3rem;
            width: 3rem;
            margin-right: 2rem;
            color: #43474c;
          }
        }
      }
    } 
  }
`;
