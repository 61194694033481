import React from "react";
import styled from "styled-components";
import Seo from "../comps/Seo";
import Navbar from "../comps/Navbar";
import Footer from "../comps/Footer";
import HeaderInfo from "../comps/info/HeaderInfo";
import ContentInfo from "../comps/info/ContenInfo";
import { palette } from "../styles/styles";
import PreFooterInfo from "../comps/info/PreFooterInfo";
import last from "../images/info/last.png";
import circle from "../images/sections/circle.png";
import DrawerMenu from "../comps/DrawerMenu";

const info = (props) => {
  const { className, location } = props;

  return (
    <>
      <Seo />
      <div className={className}>
        <Navbar />
        <DrawerMenu />
        <HeaderInfo />
        <ContentInfo location={location} />
        <PreFooterInfo />
        <Footer />
      </div>
    </>
  );
};

export default styled(info)`
  background-color: ${palette.bgWhite};
  max-width: 100%;
  overflow-x: hidden !important;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 2rem;
  background-image: url(${circle}), url(${circle}), url(${last});
  background-attachment: local;
  background-repeat: no-repeat;
  @media (min-width: 1441px) {
    background-position: 130rem 40rem, -10rem 90rem, -16rem 140rem;
    background-size: 40rem, 20rem, 230rem;
  }
  @media (max-width: 1440px) {
    background-position: 130rem 40rem, -10rem 90rem, -16rem 140rem;
    background-size: 40rem, 20rem, 230rem;
  }
  @media (max-width: 1024px) {
    background-position: 120rem 40rem, -16rem 90rem, -16rem 140rem;
    background-size: 40rem, 28rem, 230rem;
  }
  @media (max-width: 768px) {
    background-position: 85rem 50rem, -35rem 115rem, -80rem 70rem;
    background-size: 50rem, 50rem, 12rem;
    grid-row-gap: 2rem;
  }
`;
