import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ChevronLeft, ChevronRight } from "@styled-icons/fa-solid";
import { chevronsButton, flexRowSeCt, palette} from "../../styles/styles";
import { goPriorOrNext } from "../../utils/compsUtils";
import DetailsCard from "../DetailsCard";
import ShortDetailsCard from "../ShortDetailsCard";

const PreFooterInfo = (props) => {

  const { className } = props;
  const cards = [
    {
      name: "Capacitaciones grupales con expertos (en vivo)",
      desc: "Tendrás acceso a 10 horas de contenido en vivo con expertos en los temas más actualizados y necesarios para conseguir trabajo en el mundo real. Algunos de los temas que verás son:",
      bullets: [
        "LinkedIn",
        "CV / Hoja de vida",
        "Marca personal",
        "Entrevistas laborales",
      ],
      index: 0,
    },
    {
      name: "Sesiones individuales 1 a 1",
      desc:
        "Tendrás un career coach que te ayudará a desarrollar tu perfil profesional y te acompañará durante todo el programa a través de sesiones personalizadas quincenales.\n" +
        "\n" +
        "Adicionalmente, contarás con 5 horas on demand en las que podrás pedir asesoría para algún tema en específico como:",
      bullets: [
        "Revisión de CV",
        "Asesoría en búsqueda de empleo",
        "Simulación de entrevista (español o inglés)",
      ],
      index: 1,
    },
    {
      name: "Paquete de heramientas\n digitales incluido",
      desc: "Contarás con acceso y capacitación a distintas herramientas digitales para ayudarte en tu búsqueda laboral.",
      bullets: [
        "Growth hacking",
        "Software de seguimiento de postulaciones",
        "Entre otras",
      ],
      index: 2,
    },
  ];
  const [currentStep, setCurrentStep] = useState(1);
  const changeStep = (action) => {
    goPriorOrNext(action, cards.length, currentStep, setCurrentStep);
  }

  return (
    <section className={className}>
      <h2>Siguientes pasos</h2>
      <div className="nextSteps">
        {cards.map((c) => (
          <ShortDetailsCard
            infoCard={c}
            isOpen
          />
        ))}
      </div>
      <div className="mobileNextSteps">
        <button
          className="iconButton"
          type="button"
          onClick={() => changeStep("prior")}
        >
          <ChevronLeft className="icon" />
        </button>
        <ShortDetailsCard
          infoCard={cards[currentStep]}
          isOpen
        />
        <button
          className="iconButton"
          type="button"
          onClick={() => changeStep("next")}
        >  <ChevronRight className="icon" />
        </button>
      </div>
    </section>
  );
};

export default styled(PreFooterInfo)`
  margin-bottom: -2rem;
  background-color: ${palette.bgFaq};
  padding: 2% 8%;
  h2 {
    font-size: 3rem;
    color: ${palette.fontBlue};
    margin-bottom: 2rem;
    @media (max-width: 768px) {
      width: max-content;
      margin: 2rem auto;
    }
  }
  .nextSteps {
    ${flexRowSeCt};
    grid-gap: 2rem;
    align-items: stretch;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .mobileNextSteps {
    height: max-content;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    align-items: center;
    justify-items: center;
    padding: 0 2rem;
    .iconButton {
      ${chevronsButton};
    }
    @media (min-width: 769px) {
      display: none;
    }
  }
`;
