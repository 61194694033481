import React from "react";
import styled from "styled-components";
import headerInfo1 from "../../images/info/headerInfo1.png";
import logoZ from "../../images/logoZBig.jpg";
import { ArrowBack } from "@material-ui/icons";
import { palette } from "../../styles/styles";
import { Link } from "gatsby";

const info = (props) => {
  const { className } = props;

  return (
    <section className={className}>
      <Link to="/" className="link">
        <ArrowBack name="Back" className="icon" />
        <label htmlFor="Back">Volver</label>
      </Link>
      <figure className="headerImg1">
        <img src={headerInfo1} alt="info" />
      </figure>
      <figure className="headerImg2">
        <img src={logoZ} alt="info" />
      </figure>
    </section>
  );
};

export default styled(info)`
  padding: 0 8%;
  width: 100%;
  .link {
    display: flex;
    font-size: 2.4rem;
    color: ${palette.fontRed};
    margin-bottom: 1rem;
    align-items: center;
    text-decoration: none;
    color: ${palette.fontRed};
    .icon {
      font-size: 3rem;
      margin-right: 1rem;
      color: ${palette.fontRed};
    }
  }
  .headerImg1 {
    width: 100%;
  }
  .headerImg2 {
    img {
    border-radius: 50%;
    }
    width: 8rem;
    position: relative;
    top: -4rem;
    left: 4%;
  }
`;
